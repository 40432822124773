import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Please use with care:`}</strong>{` The RichContent supports only a subset of the design
system, use other components where possible instead!`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`BETA:`}</strong>{` The RichContent component is currently in an experimental phase.
Feel free to try it out, but know that it's API might change.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=RichContent wrap=flex",
        "componentName": "RichContent",
        "wrap": "flex"
      }}>{`<RichContent>
  <h1>Heading1</h1>
  <h2>Heading2</h2>
  <h3>Heading3</h3>
  <h4>Heading4</h4>
  <h5>Heading5</h5>
  <h6>Heading6</h6>
  <p>Paragraph</p>
  <p>
    Pariatur cillum quis sunt laboris culpa voluptate anim pariatur veniam
    occaecat irure veniam. Incididunt occaecat nostrud laboris tempor est
    consequat fugiat sit eu non anim aliqua 2.00. Nulla nisi voluptate
    exercitation sint <small>small</small> ex do magna veniam culpa occaecat
    incididunt. Ipsum excepteur sit eiusmod incididunt do aliquip Lorem
    adipisicing id esse aliquip sunt anim.
  </p>
  <p>
    Pariatur cillum quis <a href="#">Hello, I'm a link</a> sunt laboris culpa
    voluptate anim pariatur veniam occaecat irure veniam. Incididunt occaecat
    nostrud laboris tempor est consequat fugiat sit eu non anim aliqua 2.00.
    Nulla nisi voluptate exercitation sint <small>small</small> ex do magna
    veniam culpa occaecat incididunt. Ipsum excepteur sit eiusmod incididunt do
    aliquip Lorem adipisicing id esse aliquip sunt anim.
  </p>
</RichContent>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="RichContent" mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      